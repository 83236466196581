import {join} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {ICarListOfferResponse} from "../../meta_data/reducers/meta_data_reducer";

interface IFavouriteOfferListResponse {
    meta: {
        total_investments: number;
        total_offers: number;
    };
    data: ICarListOfferResponse[];
}

export const fetchFavouriteCarListTypes = createRequestActionTypes({view: "FavouriteCars", type: "GET", name: "FavouriteOffersDynamic"});
export const fetchFavouriteCarList = () => (dispatch: Dispatch, getState: any) => {
    dispatch({type: fetchFavouriteCarListTypes.start});
    const favouriteCars = getState().favourites.favourites;
    const link = apiLink.favourites.base({})(null);
    const url = appendQueryString(link, {ids: join(favouriteCars, ",")});

    return getRequest({}, url).then(async (json: IFavouriteOfferListResponse) => {
        const sortedOffers = json.data.sort((a, b) => {
            return favouriteCars.indexOf(a.id) - favouriteCars.indexOf(b.id);
        });

        dispatch({type: fetchFavouriteCarListTypes.success, result: sortedOffers});
        return sortedOffers;
    });
};

export const removeFetchedFavourite = (carId: number) => async (dispatch: Dispatch, getState: () => IStore) => {
    const oldFavourites = getState().favourites.favouriteCarList;

    const favourites = oldFavourites.filter((fav) => {
        return fav.id !== carId;
    });

    return dispatch({
        type: fetchFavouriteCarListTypes.success,
        result: favourites
    });
};
