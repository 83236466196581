import {ReactNode} from "react";
import {css} from "@emotion/react";

interface IWrapperProps {
    children?: ReactNode;
}

export const DashboardWrapper = (props: IWrapperProps) => {
    return <div css={dashStyle}> {props.children}</div>;
};

const dashStyle = css`
    width: 100%;
    min-height: 600px;
    display: block;
`;
