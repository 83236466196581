import {useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@rw/button";
import {carsTheme} from "@rw/themes";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {Spinner} from "../../app/components/Spinner";
import {apiLink} from "../../app/routes/api_link";
import {postRequest} from "../../app/utils/request_response_utils/request";
import {ml_md} from "../../styles/helpers";

interface ResetFormValues {
    email: string;
}
const resetInitialValues: ResetFormValues = {
    email: ""
};

const resetValidationSchema = Yup.object({
    email: Yup.string().required("Email is required")
});

export const ResetForm = () => {
    const [error, setError] = useState<{reset: boolean | null; message: string | null}>({reset: null, message: null});

    const handleSubmit = async (values: ResetFormValues, {resetForm}: {resetForm: () => void}) => {
        const url = apiLink.resetPassword({})(null);

        return postRequest({}, url, {email: values.email})
            .then((res: any) => {
                console.log("res", res);
                setError({reset: true, message: "Sprawdź email i postepuj z instrukcją aby zminić hasło."});
                resetForm();
                return res;
            })
            .catch((err) => {
                console.warn("err on reset", err);
            });
    };

    return (
        <>
            <Formik<ResetFormValues>
                initialValues={resetInitialValues}
                validationSchema={resetValidationSchema}
                validate={(values) => {
                    const errors: any = {};
                    if (!values.email) {
                        errors.email = <p style={{color: "red", margin: "0 0 10px 0"}}>Required </p>;
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = <p style={{color: "red", margin: "0 0 10px 0"}}>Invalid email address </p>;
                    }
                    return errors;
                }}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, errors, touched}) => {
                    return (
                        <FormWrapper>
                            <h2>Zresetuj hasło</h2>
                            <StyledForm>
                                <Field css={loginInput} name="email" type="email" placeholder="Email" required />
                                {errors.email && touched.email && errors.email}

                                <div css={submitWrapper}>
                                    <Button type="submit" size="lg" variant="primary" disabled={isSubmitting}>
                                        Zresetuj hasło
                                    </Button>
                                    {isSubmitting && (
                                        <div css={spinnerWrapper}>
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            </StyledForm>
                            {error.reset === false && <div css={errorLogin}>{error.message}</div>}
                            {error.reset === true && <div css={successLogin}>Sprawdź email i postepuj z instrukcją aby zminić hasło. </div>}
                        </FormWrapper>
                    );
                }}
            </Formik>
        </>
    );
};

const spinnerWrapper = css`
    margin-left: 2rem;
`;
const submitWrapper = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
`;

const errorLogin = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.brand_danger};
    border: 1px solid ${carsTheme.colors.brand_danger};
    background: #f8d7da;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const successLogin = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.success_color};
    border: 1px solid ${carsTheme.colors.success_color};
    background: ${carsTheme.colors.success_color_light};
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const loginInput = css`
    border: none;
    border-bottom: 2px solid ${carsTheme.colors.gray_light};
    padding-block: 10px;

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border: none;
        border-bottom: 2px solid ${carsTheme.colors.brand_primary};
        outline: none;
    }
`;

const FormWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 40px 0;

    background: #fff;
    z-index: 10;
    position: relative;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
