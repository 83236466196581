import {useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@rw/button";
import {carsTheme} from "@rw/themes";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {apiLink} from "../../app/routes/api_link";
import {postRequest} from "../../app/utils/request_response_utils/request";

interface AddCarFormValues {
    title: string;
    content: string;
    expiry: number;
}
//TODO change expiry to lower when more data available
const addCarInitialValues: AddCarFormValues = {
    title: "",
    content: "",
    expiry: 30
};

const addCarValidationSchema = Yup.object({
    title: Yup.string().required("Tytuł jest wymagany")
});

interface IAddCarProps {
    user: {
        id: number | null;
    };
}

export const AddCar = (props: IAddCarProps) => {
    const [error, setError] = useState<{add: boolean | null; message: string | null}>({add: null, message: null});

    const handleSubmit = async (values: AddCarFormValues, {resetForm}: {resetForm: () => void}) => {
        const data = {
            author_id: props.user.id || 1,
            title: values.title,
            content: values.content,
            expiry: values.expiry
        };

        const url = apiLink.addCar({})(data);

        return postRequest({}, url, data)
            .then((res: any) => {
                console.log("response", res);

                return res;
            })
            .catch((err) => {
                console.warn("err on reset", err);
            });
    };

    return (
        <>
            <Formik<AddCarFormValues>
                initialValues={addCarInitialValues}
                validationSchema={addCarValidationSchema}
                validate={(values) => {
                    const errors: any = {};
                    if (values.title.length < 2) {
                        errors.title = <p style={{color: "red", margin: "0 0 10px 0"}}>Popraw, za krótkie!</p>;
                    } else if (values.title.length > 150) {
                        errors.title = <p style={{color: "red", margin: "0 0 10px 0"}}>Popraw, za długie!</p>;
                    }

                    return errors;
                }}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, errors, touched}) => {
                    return (
                        <FormWrapper>
                            <h2 css={{margin: "2rem 0"}}>Dodaj Ogłoszenie</h2>
                            <StyledForm>
                                <Field css={inputStyle} name="title" type="text" placeholder="Tytuł" required />
                                {errors.title && touched.title && errors.title}
                                <Field css={inputStyle} name="content" as="textarea" rows={6} placeholder="Opis" />

                                <FieldWrapper>
                                    <label htmlFor="expiry">Czas oferty (publikacja dni na portalu)</label>
                                    <Field css={inputStyle} name="expiry" type="number" placeholder="Czas oferty" />
                                </FieldWrapper>

                                <div>
                                    <Button type="submit" size="lg" variant="primary" disabled={isSubmitting}>
                                        Dodaj ogłoszenie
                                    </Button>
                                </div>
                            </StyledForm>
                            {error.add === false && <div css={errorAddCar}>{error.message}</div>}
                            {error.add === true && <div css={successAddCar}>Ogłoszenie dodane</div>}
                        </FormWrapper>
                    );
                }}
            </Formik>
        </>
    );
};
//TODO this style used in many forms add Component
const errorAddCar = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.brand_danger};
    border: 1px solid ${carsTheme.colors.brand_danger};
    background: #f8d7da;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const successAddCar = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.success_color};
    border: 1px solid ${carsTheme.colors.success_color};
    background: ${carsTheme.colors.success_color_light};
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const inputStyle = css`
    border: 1px solid #a7a7ab;
    padding-block: 10px;
    border-radius: 8px;
    padding-left: 0.6rem;
    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border-color: ${carsTheme.colors.brand_primary};
        outline: none;
    }

    &::placeholder {
        padding-left: 0.6rem;
    }
`;

const FormWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 40px 0;

    background: #fff;
    z-index: 10;
    position: relative;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        font-size: 14px;
        font-weight: bold;
    }
`;
