import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {CarBrand} from "./CarBrand";
import heroImg from "../../styles/assets/background/car-hero.jpg";

import volvo from "../../../assets/popular_models/volvo.jpg";
import volkswagen from "../../../assets/popular_models/volkswagen.jpg";
import audi from "../../../assets/popular_models/audi.jpg";
import bmw from "../../../assets/popular_models/bmw.jpg";
//import toyota from "../../../assets/popular_models/toyota.jpg";
import skoda from "../../../assets/popular_models/skoda.jpg";
import peugeot from "../../../assets/popular_models/peugeot.jpg";
import renault from "../../../assets/popular_models/renault.jpg";
import ford from "../../../assets/popular_models/ford.jpg";
import mercedesBenz from "../../../assets/popular_models/mercedes.jpg";

// Creating the popular car brands array with imported images
const popularCarBrands = [
    {name: "Volkswagen", image: volkswagen},
    {name: "Volvo", image: volvo},
    {name: "Audi", image: audi},
    {name: "BMW", image: bmw},
    {name: "Toyota", image: volvo},
    {name: "Skoda", image: volvo},
    {name: "Peugeot", image: volvo},
    {name: "Renault", image: volvo},
    {name: "Ford", image: volvo},
    {name: "Mercedes-Benz", image: mercedesBenz}
];
export const CarBrandsSection: React.FC = () => {
    return (
        <section css={padding}>
            <Container>
                <h2 css={h2}>Najpopularniejsze marki samochodów</h2>
                <div css={carBrandsGrid}>
                    {popularCarBrands.map((brand) => (
                        <CarBrand key={brand.name} name={brand.name} image={brand.image} />
                    ))}
                </div>
            </Container>
        </section>
    );
};

const padding = css`
    padding: 20px;
`;

const h2 = css`
    text-align: center;
    margin-bottom: 20px;
`;
const carBrandsGrid = (theme: Theme) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (min-width: ${theme.breakpoints.screen_xxs}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: ${theme.breakpoints.screen_xs}) {
        grid-template-columns: repeat(3, 1fr);
        & > *:nth-last-child(1) {
            grid-column: 2 / 3;
        }
    }
    @media (min-width: ${theme.breakpoints.screen_md}) {
        grid-template-columns: repeat(4, 1fr);
        & > *:nth-last-child(2),
        & > *:nth-last-child(1) {
            grid-column: span 2;
            justify-self: center;
        }
    }
    @media (min-width: ${theme.breakpoints.screen_lg}) {
        grid-template-columns: repeat(5, 1fr);
        & > *:nth-last-child(2),
        & > *:nth-last-child(1) {
            grid-column: span 1;
            justify-self: center;
        }
    }
`;
export const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;
