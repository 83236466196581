import {filter as _filter, truncate as _truncate} from "lodash";

export const truncate = (value: string, length: number) => {
    return _truncate(value, {
        length: length,
        separator: /,? +/,
        omission: String.fromCharCode(8230)
    });
};

export const betterJoin = (value: string[]) => _filter(value, (v: string) => !!v).join(", ");

export const nowrap = (value: string) => value.replace(/ /g, String.fromCharCode(160));

export const sup2 = String.fromCharCode(178);

export const nbsp = String.fromCharCode(160);
