import {useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@rw/button";
import {appPath} from "@rw/cars_routes";
import {carsTheme} from "@rw/themes";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

interface LoginFormValues {
    email: string;
    password: string;
}
const loginInitialValues: LoginFormValues = {
    email: "",
    password: ""
};

const loginValidationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required")
});

export const LoginForm = () => {
    const [error, setError] = useState<{login: boolean | null; message: string | null}>({login: null, message: null});
    const handleSubmit = async (values: LoginFormValues, {resetForm}: {resetForm: () => void}) => {
        try {
            const response = await fetch("https://rw-web-api.eu/wp-json/jwt-auth/v1/token/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: values.email,
                    password: values.password
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Login failed");
            }

            const data = await response.json();
            setError({login: true, message: ""});
            localStorage.setItem("token", data.token);
            setTimeout(() => {
                window.location.href = appPath.admin.userDashboard;
            }, 1500);

            //end
            resetForm();
        } catch (error) {
            console.error("Error:", error);
            setError({login: false, message: "Spróobuj ponownie"});
            // Handle error state or show a notification
        }
    };

    return (
        <>
            <Formik<LoginFormValues>
                initialValues={loginInitialValues}
                validationSchema={loginValidationSchema}
                validate={(values) => {
                    const errors: any = {};
                    if (!values.email) {
                        errors.email = <p style={{color: "red", margin: "0 0 10px 0"}}>Required </p>;
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = <p style={{color: "red", margin: "0 0 10px 0"}}>Invalid email address </p>;
                    }

                    if (values.password.length > 15) {
                        errors.password = <p style={{color: "red", margin: "0 0 10px 0"}}>Too Long </p>;
                    }
                    return errors;
                }}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, errors, touched}) => {
                    return (
                        <FormWrapper>
                            <StyledForm>
                                <Field css={loginInput} name="email" type="email" placeholder="Email" required />
                                {errors.email && touched.email && errors.email}
                                <Field css={loginInput} name="password" type="password" placeholder="Hasło" required />
                                {errors.password && touched.password && errors.password}

                                <div>
                                    <Button type="submit" size="lg" variant="primary" disabled={isSubmitting}>
                                        Zaloguj się
                                    </Button>
                                </div>
                            </StyledForm>
                            {error.login === false && <div css={errorLogin}>{error.message}</div>}
                            {error.login === true && <div css={successLogin}>Poprawne logowanie</div>}
                        </FormWrapper>
                    );
                }}
            </Formik>
        </>
    );
};
const errorLogin = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.brand_danger};
    border: 1px solid ${carsTheme.colors.brand_danger};
    background: #f8d7da;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const successLogin = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.success_color};
    border: 1px solid ${carsTheme.colors.success_color};
    background: ${carsTheme.colors.success_color_light};
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const loginInput = css`
    border: none;
    border-bottom: 2px solid ${carsTheme.colors.gray_light};
    padding-block: 10px;

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border: none;
        border-bottom: 2px solid ${carsTheme.colors.brand_primary};
        outline: none;
    }
`;

const FormWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 40px 0;

    background: #fff;
    z-index: 10;
    position: relative;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
