import {getCookie, removeCookie} from "@rw/request_utils";
import {filter, includes, uniq} from "lodash";
import * as lscache from "lscache";
import {Dispatch} from "redux";

import {FAVOURITES_COOKIE_NAME, FAVOURITES_LS_NAME} from "../constants/storage_keys";
import {IAction} from "../interfaces/IAction";

export interface ILoadLocalStorageFavouritesToStore extends IAction {
    cars: number[];
}

export const loadFavouritesToStore = (cars: number[]) => async (dispatch: Dispatch) => {
    return dispatch({type: LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE, cars});
};

export const LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE = "LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE";
export const loadLocalStorageFavouritesToStore =
    () =>
    async (dispatch: Dispatch): Promise<any> => {
        // FM used to store favourites in cookies. Code below makes sure to transfer favs from cookies to LS.
        const favouritesFromCookies: string = getCookie(FAVOURITES_COOKIE_NAME) || "";
        const favouritesFromLS: string[] = lscache.get(FAVOURITES_LS_NAME) || [];
        const favourites = uniq([...(favouritesFromCookies ? JSON.parse(favouritesFromCookies) : []), ...favouritesFromLS]);
        if (favouritesFromCookies) {
            removeCookie(FAVOURITES_COOKIE_NAME);
            lscache.set(FAVOURITES_LS_NAME, favourites);
        }
        return dispatch(loadFavouritesToStore(favourites));
    };

export interface ISetFavouriteCar extends IAction {
    car: string;
}

export const SET_FAVOURITE_CARS = "SET_FAVOURITE_CARS";
export const setFavouriteCar =
    (car: number) =>
    async (dispatch: Dispatch): Promise<any> => {
        const favouritesFromCookies: string = getCookie(FAVOURITES_COOKIE_NAME) || "";
        const favouritesFromLS: string[] = lscache.get(FAVOURITES_LS_NAME) || [];
        const favourites = uniq([...(favouritesFromCookies ? JSON.parse(favouritesFromCookies) : []), ...favouritesFromLS]);
        if (favourites) {
            if (includes(favourites, car)) {
                const offersToBeSaved = filter(favourites, (favouriteOffer) => favouriteOffer !== car);
                lscache.set(FAVOURITES_LS_NAME, offersToBeSaved);
            } else {
                const offersToBeSaved = [car, ...favourites];
                lscache.set(FAVOURITES_LS_NAME, offersToBeSaved);
            }
        } else {
            lscache.set(FAVOURITES_LS_NAME, [car]);
        }
        dispatch({type: SET_FAVOURITE_CARS, car});
    };
