import {css} from "@emotion/react";
import {carsTheme} from "@rw/themes";

export enum AlertType {
    success = "success",
    warning = "warning",
    danger = "danger",
    info = "info"
}

interface IAlertProps {
    message: string | null;
    type: AlertType | null;
}

const typeSwitcher = (type: AlertType) => {
    switch (type) {
        case AlertType.success:
            return success;
        case AlertType.warning:
            return warning;
        case AlertType.danger:
            return error;
        case AlertType.info:
            return info;
        default:
            return defaultStyle;
    }
};

export const Alert = (props: IAlertProps) => {
    const {message, type} = props;
    if (!message) return null;
    if (!type) return null;
    const alertStyle = typeSwitcher(type);

    return <div css={alertStyle}>{message}</div>;
};

const defaultStyle = css`
    color: slategray;
`;
const warning = css`
    color: orangered;
`;
const info = css`
    color: dodgerblue;
`;

const error = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.brand_danger};
    border: 1px solid ${carsTheme.colors.brand_danger};
    background: #f8d7da;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const success = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.success_color};
    border: 1px solid ${carsTheme.colors.success_color};
    background: ${carsTheme.colors.success_color_light};
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
