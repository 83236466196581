import * as React from "react";
import {css, Theme} from "@emotion/react";

import heroImg from "../../styles/assets/background/car-hero.jpg";

export const HeroSection = () => {
    return (
        <>
            <section css={heroWrapper}>
                <h1 css={heroClaim}>Zmień samochód.</h1>

                <p css={heroSubClaim}>Znajdziesz swój smaochód tutaj!</p>
            </section>
        </>
    );
};

const heroWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 300px;
    padding: 5rem 1rem 2rem;
    z-index: 1;
    background-image: url("${heroImg}");
    background-repeat: no-repeat;
    background-position: center -4rem;
    background-size: cover;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 3rem 2rem 2rem;
        height: 400px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 1rem;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0.21) 70%, rgba(0, 0, 0, 0.47) 100%);

        @media screen and (min-width: ${theme.breakpoints.screen_md}) {
            display: none;
        }
    }
`;

export const heroClaim = (theme: Theme) => css`
    margin: 0 auto;
    color: white;
    text-align: center;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.5;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        width: 650px;
        font-size: 4rem;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 6rem;
    }
`;

const heroSubClaim = (theme: Theme) => css`
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
    margin-top: 0.8rem;
    margin-bottom: 3rem;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: 2rem;
        margin-top: 1rem;
        margin-bottom: 2.8rem;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 2.2rem;
    }
`;
