import * as React from "react";
import {useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {carsTheme} from "@rw/themes";
import {lighten} from "polished";

import {bgc_primary, bgc_primary_hover} from "../../styles/helpers";
import {RegisterForm} from "../register/RegisterForm";
import {ResetForm} from "../reset/ResetForm";
import {LoginForm} from "./LoginForm";

export const LoginPageDynamic = () => {
    const [activeForm, setActiveForm] = useState<"login" | "register" | "reset">("login");
    const handleFormChange = (FormType: "login" | "register" | "reset") => {
        setActiveForm(FormType);
    };

    return (
        <>
            <section css={pageWrapper}>
                <div css={leftCol}>
                    <div css={leftContent}>
                        <h1>Zaloguj się lub zarejstruj aby dodać ogłoszenie.</h1>
                        <p>
                            Utwórz konto, aby dodawać ogłoszenia i zarządzać nimi w jednym miejscu. Logowanie pozwala na szybki dostęp do wszystkich funkcji
                            naszej platformy, co ułatwia korzystanie z usług.
                        </p>
                        <p>
                            Dołącz do naszej społeczności i ciesz się prostotą dodawania ogłoszeń. Dzięki rejestracji zyskujesz możliwość edytowania,
                            archiwizowania oraz śledzenia swoich ogłoszeń na bieżąco.
                        </p>
                    </div>
                    <div css={circleStyle}></div>
                </div>
                <div css={rightCol}>
                    <div css={rightColInner}>
                        <div css={formSelect}>
                            <FormsButton
                                type="button"
                                css={[activeForm === "login" ? active : null, {marginRight: "2rem"}]}
                                onClick={() => {
                                    handleFormChange("login");
                                }}
                            >
                                Zaloguj
                            </FormsButton>
                            <FormsButton
                                type="button"
                                css={activeForm === "register" ? active : null}
                                onClick={() => {
                                    handleFormChange("register");
                                }}
                            >
                                Zarejestruj
                            </FormsButton>
                        </div>
                        <div css={formsWrapper}>
                            {activeForm == "login" && <LoginForm />}
                            {activeForm == "register" && <RegisterForm formChange={handleFormChange} />}
                            {activeForm == "reset" && <ResetForm />}
                        </div>
                        {activeForm === "login" ? (
                            <p>
                                Nie pamietasz hasła?{" "}
                                <span
                                    css={link}
                                    onClick={() => {
                                        handleFormChange("reset");
                                    }}
                                >
                                    zmień hasło
                                </span>
                            </p>
                        ) : null}
                    </div>
                </div>
            </section>
        </>
    );
};
const link = css`
    color: ${carsTheme.colors.brand_primary};

    &:hover {
        cursor: pointer;
        color: ${lighten(0.2, carsTheme.colors.brand_primary)};
    }
`;
const formSelect = css`
    display: flex;
    flex-direction: row;
`;

const leftContent = (theme: Theme) => css`
    width: 100%;
    z-index: 100;
    color: #fff;
    position: relative;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        width: 70%;
    }
`;

const pageWrapper = (theme: Theme) => css`
    width: 100%;
    height: auto;
    flex-direction: column;
    
    @media (min-width: ${theme.breakpoints.screen_md}) {

    height: Calc(100vh - 70px);
    display: flex;
    flex-direction: row;
`;

const leftCol = (theme: Theme) => css`
    width: 100%;
    position: relative;
    min-height: 350px;
    display: flex;
    background-image: linear-gradient(to right, #ff8a49, #ff5a00);
    padding: 4rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        width: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const formsWrapper = css`
    padding: 0;
    min-height: 320px;
    display: flex;
    align-items: end;
`;

const rightColInner = css`
    width: 70%;
`;
const rightCol = (theme: Theme) => css`
    padding: 4rem;

    width: 100%;
    position: relative;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        width: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

const circleStyle = css`
    width: 80%;
    height: 0;
    padding-top: 80%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ff5a00;
        border-top-right-radius: 100%;
        box-shadow: 5px -10px 12px -17px #ff5a00;
    }
`;

const FormsButton = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px 26px;
    font-size: 16px;
    color: ${carsTheme.colors.brand_primary};
    background: #fff;
    // ${bgc_primary};
    border: none;
    border-bottom: 2px solid #fff;

    cursor: pointer;
    min-width: 126px;
    height: 42px;

    &:hover {
        ${bgc_primary_hover};
        color: ${carsTheme.colors.brand_black};
        background: #fff;
        border-bottom: 2px solid ${carsTheme.colors.brand_primary};
    }
`;
const active = css`
    border-bottom: 2px solid ${carsTheme.colors.brand_primary};
    color: ${carsTheme.colors.brand_black};
`;
