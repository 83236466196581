import {filter, isArray, isNumber, last, repeat, trim} from "lodash";
import numeral from "numeral";
import {nbsp, nowrap, pluralize, sup2} from "@rw/string_utils";

import "numeral/locales/pl";

/**
 * If you're looking for specific formatter, scroll down (line ~>= 90)
 */

// Select Polish locale from `numeral`
numeral.locale("pl");

// Interface for exported formatters (`numberFormat`, `areaFormat` etc.)
interface IOwnOptions {
    human?: boolean;
    precision?: number;
    unit?: string | [string, string, string];
}

// Interface for `numberParse`
interface IOptions extends IOwnOptions {
    unitRange?: string | [string, string];
    unitSeparator?: string;
    unitZero?: string;
}

// Wrapper for `numeral`
const baseNumberFormat = (value: number, precision = 2, human = false) => {
    // Build format for `numeral` method
    const precisionFormat = repeat("0", precision);
    const humanFormat = human ? " a" : "";
    const numeralFormat = `0,0.[${precisionFormat}]${humanFormat}`;
    return trim(numeral(value).format(numeralFormat));
};

// Base formatter
const numberParse = (value: number | [number, number], options?: IOptions) => {
    const precision = (options && options.precision) || 0;
    const human = (options && options.human) || false;
    const unit = (options && options.unit) || "";
    const unitRange = (options && options.unitRange) || "";
    const unitSeparator = (options && options.unitSeparator) || "";
    const unitZero = (options && options.unitZero) || "";

    // Value is 0
    if (value === 0) {
        return unitZero ? unitZero : value.toString();
    }

    // Value is a number other than 0
    if (isNumber(value)) {
        const parsedUnit = (isArray(unit) && pluralize(unit)(value)) || unit;
        return nowrap(baseNumberFormat(value, precision, human) + unitSeparator + parsedUnit);
    }

    // Value is a range
    if (isArray(value)) {
        // Format and filter falsy values
        const formattedValues = filter(
            [
                value[0] && nowrap(`od${nbsp}${baseNumberFormat(value[0], precision, human)}`),
                value[1] && nowrap(`do${nbsp}${baseNumberFormat(value[1], precision, human)}`)
            ],
            (v) => !!v
        );

        // Range values are identical
        if (value[0] === value[1]) {
            const parsedUnit = (isArray(unit) && pluralize(unit)(value[0])) || unit;
            return nowrap(baseNumberFormat(value[0], precision, human) + unitSeparator + parsedUnit);
        }

        // One of range value is falsy
        if (formattedValues.length === 1) {
            const parsedUnit = (isArray(unitRange) && pluralize([unitRange[0], unitRange[1], unitRange[1]])(value[0] || value[1])) || unitRange || unit;
            return nowrap(formattedValues[0] + unitSeparator! + parsedUnit); // eslint-disable-line @typescript-eslint/no-non-null-assertion
        }

        // Range is correct
        const parsedUnit = (isArray(unitRange) && last(unitRange)) || unitRange || unit;
        return formattedValues.join(" ") + unitSeparator + parsedUnit;
    }
};

// Custom, exported formatters
export const numberFormat = (value: number | [number, number], options?: IOwnOptions) => numberParse(value, options);

export const areaFormat = (value: number | [number, number], options?: IOwnOptions) => numberParse(value, {unit: `m${sup2}`, unitSeparator: nbsp, ...options});

export const distanceFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {precision: 2, unit: "m", unitSeparator: nbsp, ...options});

export const priceFormat = (value: number | [number, number], options?: IOwnOptions) => numberParse(value, {unit: "zł", unitSeparator: nbsp, ...options});

export const priceM2Format = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {unit: `zł/m${sup2}`, unitSeparator: nbsp, ...options});

export const roomsFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {
        unit: ["pokój", "pokoje", "pokoi"],
        unitRange: ["pokoju", "pokoi"],
        unitSeparator: nbsp,
        ...options
    });

export const floorFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {unit: "piętro", unitRange: "piętra", unitZero: "parter", unitSeparator: nbsp, ...options});

export const floorShortFormat = (value: number | [number, number], options?: IOwnOptions) => numberParse(value, {unitZero: "parter", ...options});

export const floorsFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {
        unit: ["kondygnacja", "kondygnacje", "kondygnacji"],
        unitRange: "kondygnacji",
        unitSeparator: nbsp,
        ...options
    });

export const bathroomsFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {
        unit: ["łazienka", "łazienki", "łazienek"],
        unitRange: ["łazienki", "łazienek"],
        unitSeparator: nbsp,
        ...options
    });

export const offersFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {
        unit: ["inwestycja", "inwestycje", "inwestycji"],
        unitRange: "inwestycji",
        unitSeparator: nbsp,
        ...options
    });

export const propertiesFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {
        unit: ["oferta", "oferty", "ofert"],
        unitRange: ["oferty", "ofert"],
        unitSeparator: nbsp,
        ...options
    });

export const flatsFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {
        unit: ["mieszkanie", "mieszkania", "mieszkań"],
        unitRange: "mieszkań",
        unitSeparator: nbsp,
        ...options
    });

export const housesFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {unit: ["dom", "domy", "domów"], unitRange: "domów", unitSeparator: nbsp, ...options});

export const commercialsFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {
        unit: ["lokal", "lokale", "lokali"],
        unitRange: ["lokale", "lokali"],
        unitSeparator: nbsp,
        ...options
    });

export const heightFormat = (value: number | [number, number], options?: IOwnOptions) => numberParse(value, {unit: "cm", unitSeparator: nbsp, ...options});

export const articlesFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {unit: ["artykuł", "artykuły", "artykułów"], unitSeparator: nbsp, ...options});

export const vendorsFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {unit: ["dewelopera", "deweloperów", "deweloperów"], unitSeparator: nbsp, ...options});

export const promotionsFormat = (value: number | [number, number], options?: IOwnOptions) =>
    numberParse(value, {unit: ["promocję", "promocje", "promocji"], unitSeparator: nbsp, ...options});

// prettier-ignore
const NATIONAL_AREA_CODES = [12, 13, 14, 15, 16, 17, 18, 22, 23, 24, 25, 29, 32, 33, 34, 41, 42, 43, 44, 46, 48, 52, 54, 55, 56, 58, 59, 61, 62, 63, 65, 67, 68, 71, 74, 75, 76, 77, 81, 82, 83, 84, 85, 86, 87, 89, 91, 94, 95];
export const phoneNumberFormat = (input: string): string => {
    if (!input || !input.includes("48 ")) {
        return input;
    }

    const [countryCallingCode, phone] = input.split(" ");
    for (const code of NATIONAL_AREA_CODES) {
        if (phone.indexOf(code.toString()) == 0) {
            // format geographic number
            return nowrap(`+${countryCallingCode} ${code} ${format322(phone.slice(2))}`);
        }
    }
    // format mobile number
    return nowrap(`+${countryCallingCode} ${format333(phone)}`);
};
const format322 = (input: string): string => input.slice(0, 3) + " " + input.slice(3, 5) + " " + input.slice(5, 7);
const format333 = (input: string): string => input.slice(0, 3) + " " + input.slice(3, 6) + " " + input.slice(6, 9);

export const phoneNumberFormatNoPolishPrefix = (input: string) => {
    if (!input) {
        return input;
    }

    return phoneNumberFormat(input)
        .replace(/(^\+48)|(^48)/, "")
        .trim();
};

export const divideAndAddDash = (input: string) => {
    const trimmedString = input.replace("48 ", "");
    if (trimmedString.length !== 6) {
        return null;
    }
    const midIndex = Math.floor(trimmedString.length / 2);
    const firstPart = trimmedString.slice(0, midIndex);
    const secondPart = trimmedString.slice(midIndex);
    return firstPart + "-" + secondPart + "-xxx";
};

export const showPhoneNumberFormat = (phoneNumber: string) => {
    const whiteSpaceCount = (phoneNumber.match(/\u00a0/g) || []).length;
    return whiteSpaceCount == 3 ? phoneNumber.substring(0, 6) : phoneNumber.substring(0, 3);
};

export const numberWithDelimiter = (x: string | number, delimiter = " "): string => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
};
export const numberNoDelimiter = (x: string): string => {
    return x.replace(/\s/g, "");
};
