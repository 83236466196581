import {connect} from "react-redux";

import {EmptyListWrapper, EmptyText} from "../../favourites/components/FavouriteCarsDynamic";

interface IProps {
    id?: number;
}
const NewsDynamicC = (props: IProps) => {
    return (
        <EmptyListWrapper>
            <EmptyText style={{padding: "0 2rem"}}>
                Sekcja wiadomości jest w budowie
                <br />
                zapraszamy ponownie później.
            </EmptyText>
        </EmptyListWrapper>
    );
};

export const NewsDynamic = connect(null, null)(NewsDynamicC);
