import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@rw/button";
import {appPath} from "@rw/cars_routes";
import {carsTheme} from "@rw/themes";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {Spinner} from "../../app/components/Spinner";
// TODO find better solution
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

interface PassFormValues {
    password1: string;
    password2: string;
}
const passInitialValues: PassFormValues = {
    password1: "",
    password2: ""
};

const passValidationSchema = Yup.object({
    password1: Yup.string().required("Email is required"),
    password2: Yup.string().required("Password is required")
});

export const NewPasswordForm = () => {
    const [countdown, setCountdown] = useState(5);
    const query = useQuery();
    const resetKey = query.get("key");
    const userId = query.get("user");
    // user_id reset_key new_password
    const [error, setError] = useState<{pass: boolean | null; message: string | null}>({pass: null, message: null});
    const handleSubmit = async (values: PassFormValues) => {
        try {
            //TODO add to app api in right way
            const response = await fetch("https://rw-web-api.eu/wp-json/wp/v2/password-update", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    new_password: values.password1,
                    user_id: userId,
                    reset_key: resetKey
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Cos poszło nie tak - failed");
            }

            const data = await response.json();
            setError({pass: true, message: ""});

            console.log("Poprawna zmiana hasła:", data);
            // Handle the token and any further logic, such as saving to localStorage
        } catch (error) {
            console.error("Error:", error);
            setError({pass: false, message: "Spróobuj ponownie"});
            // Handle error state or show a notification
        }
    };

    useEffect(() => {
        if (error.pass) {
            const intervalId = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [error.pass]);

    useEffect(() => {
        if (countdown === 0) {
            window.location.href = appPath.admin.login;
        }
    }, [countdown]);

    return (
        <>
            <Formik<PassFormValues>
                initialValues={passInitialValues}
                validationSchema={passValidationSchema}
                validate={(values) => {
                    const errors: any = {};

                    // Validate password length
                    if (values.password1.length < 8) {
                        errors.password1 = <p style={{color: "red", margin: "0 0 10px 0"}}>Password is too short</p>;
                    } else if (values.password1.length > 15) {
                        errors.password1 = <p style={{color: "red", margin: "0 0 10px 0"}}>Password is too long</p>;
                    }

                    // Validate password confirmation length
                    if (values.password2.length < 8) {
                        errors.password2 = <p style={{color: "red", margin: "0 0 10px 0"}}>Password is too short</p>;
                    } else if (values.password2.length > 15) {
                        errors.password2 = <p style={{color: "red", margin: "0 0 10px 0"}}>Password is too long</p>;
                    }

                    // Check if passwords match
                    if (values.password1 !== values.password2) {
                        errors.password2 = <p style={{color: "red", margin: "0 0 10px 0"}}>Passwords do not match</p>;
                    }

                    return errors;
                }}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, errors, touched}) => {
                    return (
                        <FormWrapper>
                            <h2>Zmiana hasła</h2>

                            <StyledForm>
                                <Field css={loginInput} name="password1" type="password" placeholder="Hasło" required />
                                {errors.password1 && touched.password1 && errors.password1}
                                <Field css={loginInput} name="password2" type="password" placeholder="Hasło powtórz" required />
                                {errors.password2 && touched.password2 && errors.password2}

                                <div css={submitWrapper}>
                                    <Button type="submit" size="lg" variant="primary" disabled={isSubmitting}>
                                        Ustaw hasło
                                    </Button>
                                    {isSubmitting && (
                                        <div css={spinnerWrapper}>
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            </StyledForm>
                            {error.pass === false && <div css={errorLogin}>{error.message}</div>}
                            {error.pass === true && <div css={successLogin}>Poprawna zmiana hasła</div>}
                        </FormWrapper>
                    );
                }}
            </Formik>
        </>
    );
};

const spinnerWrapper = css`
    margin-left: 2rem;
`;
const submitWrapper = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
`;

const errorLogin = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.brand_danger};
    border: 1px solid ${carsTheme.colors.brand_danger};
    background: #f8d7da;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const successLogin = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.success_color};
    border: 1px solid ${carsTheme.colors.success_color};
    background: ${carsTheme.colors.success_color_light};
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const loginInput = css`
    border: none;
    border-bottom: 2px solid ${carsTheme.colors.gray_light};
    padding-block: 10px;

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border: none;
        border-bottom: 2px solid ${carsTheme.colors.brand_primary};
        outline: none;
    }
`;

const FormWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 40px 0;

    background: #fff;
    z-index: 10;
    position: relative;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
