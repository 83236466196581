export const parseOfferSlugToObject = (slugWithId: string) => {
    return {
        offerSlug: slugWithId.split("-").slice(0, -1).join("-"),
        carId: slugWithId.split("-").slice(-1)[0]
    };
};
export const parseDeveloperSlugToObject = (slugWithId: string) => {
    return {
        developerSlug: slugWithId.split("-").slice(0, -1).join("-"),
        developerId: slugWithId.split("-").slice(-1)[0]
    };
};
export const parseInvestmentSlugToObject = (slugWithId: string) => {
    return {
        investmentSlug: slugWithId.split("-").slice(0, -1).join("-"),
        investmentId: slugWithId.split("-").slice(-1)[0]
    };
};
export const parseProvidedObjectToSlug = (providedObject: {slug: string; id: string | number}) => {
    return `${providedObject.slug}-${providedObject.id}`;
};
