import {useEffect, useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@rw/button";
import {carsTheme} from "@rw/themes";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {Spinner} from "../../app/components/Spinner";

interface RegisterFormValues {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
}
const registerInitialValues: RegisterFormValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: ""
};

const registerValidationSchema = Yup.object({
    email: Yup.string().required("Wymagane pole!"),
    password: Yup.string().required("Wymagane pole !!!")
});

type FormChangeType = (type: "login" | "register" | "reset") => void;

interface RegisterFormProps {
    formChange: FormChangeType;
}

export const RegisterForm = ({formChange}: RegisterFormProps) => {
    const [error, setError] = useState<{register: boolean | null; message: string | null}>({register: null, message: null});
    const [countdown, setCountdown] = useState(5);
    const handleSubmit = async (values: RegisterFormValues, {resetForm}: {resetForm: () => void}) => {
        try {
            const response = await fetch("https://rw-web-api.eu/wp-json/wp/v2/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: values.email,
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.warn("errorData", errorData.error);
                setError({register: false, message: errorData.error});

                throw new Error(errorData.error || "Registration failed");
            }

            const data = await response.json();
            setError({register: true, message: ""});

            console.log("Rejestracja successful:", data);
            // Handle registration action

            //end
            resetForm();
        } catch (error) {
            console.error("Error:", error);
            setError({register: false, message: "Spróobuj ponownie"});
            // Handle error state or show a notification
        }
    };

    useEffect(() => {
        if (error.register) {
            const intervalId = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(intervalId); // wyczyść interval po zakończeniu
        }
    }, [error.register]);

    useEffect(() => {
        if (countdown === 0) {
            formChange("login");
        }
    }, [countdown]);
    return (
        <>
            <Formik<RegisterFormValues>
                initialValues={registerInitialValues}
                validationSchema={registerValidationSchema}
                validate={(values) => {
                    const errors: any = {};
                    if (!values.email) {
                        errors.email = <p style={{color: "red", margin: "0 0 10px 0"}}>Wymagane pole! </p>;
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = <p style={{color: "red", margin: "0 0 10px 0"}}>Niepoprawny email! </p>;
                    }
                    if (values.firstName.length < 2) {
                        errors.firstName = <p style={{color: "red", margin: "0 0 10px 0"}}>Popraw, za krótkie!</p>;
                    } else if (values.firstName.length > 50) {
                        errors.firstName = <p style={{color: "red", margin: "0 0 10px 0"}}>Popraw, za długie!</p>;
                    }

                    if (values.lastName.length < 2) {
                        errors.lastName = <p style={{color: "red", margin: "0 0 10px 0"}}>Popraw, za krótkie!</p>;
                    } else if (values.lastName.length > 50) {
                        errors.lastName = <p style={{color: "red", margin: "0 0 10px 0"}}>Popraw, za długie!</p>;
                    }

                    if (values.password.length > 15) {
                        errors.password = <p style={{color: "red", margin: "0 0 10px 0"}}>Popraw, za długie!</p>;
                    }
                    return errors;
                }}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, errors, touched}) => {
                    return (
                        <FormWrapper>
                            <StyledForm>
                                <Field css={loginInput} name="email" type="email" placeholder="Email" required />
                                {errors.email && touched.email && errors.email}
                                <Field css={loginInput} name="password" type="password" placeholder="Hasło" required />
                                {errors.password && touched.password && errors.password}
                                <Field css={loginInput} name="firstName" type="text" placeholder="Imię" required />
                                {errors.firstName && touched.firstName && errors.firstName}
                                <Field css={loginInput} name="lastName" type="text" placeholder="Nazwisko" required />
                                {errors.lastName && touched.lastName && errors.lastName}

                                <div css={submitWrapper}>
                                    <Button type="submit" size="lg" variant="primary" disabled={isSubmitting}>
                                        Zarejestruj się
                                    </Button>
                                    {isSubmitting && (
                                        <div css={spinnerWrapper}>
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            </StyledForm>
                            {error.register === false && <div css={errorLogin}>{error.message}</div>}
                            {error.register === true && (
                                <div css={successLogin}>
                                    Poprawna Rejestracja. <br />
                                    Przekierowanie do logowania za {countdown}
                                </div>
                            )}
                        </FormWrapper>
                    );
                }}
            </Formik>
        </>
    );
};

const spinnerWrapper = css`
    margin-left: 2rem;
`;
const submitWrapper = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
`;

const errorLogin = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.brand_danger};
    border: 1px solid ${carsTheme.colors.brand_danger};
    background: #f8d7da;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const successLogin = css`
    margin-top: 3rem;
    color: ${carsTheme.colors.success_color};
    border: 1px solid ${carsTheme.colors.success_color};
    background: ${carsTheme.colors.success_color_light};
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem;
`;
const loginInput = css`
    border: none;
    border-bottom: 2px solid ${carsTheme.colors.gray_light};
    padding-block: 10px;

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border: none;
        border-bottom: 2px solid ${carsTheme.colors.brand_primary};
        outline: none;
    }
`;

const FormWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 40px 0;

    background: #fff;
    z-index: 10;
    position: relative;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
