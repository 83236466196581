import {useCallback, useState} from "react";

import {AlertType} from "./Alert";

export const useAlert = () => {
    const [alert, setAlert] = useState<{message: string | null; type: AlertType | null}>({
        message: null,
        type: null
    });

    const showAlert = useCallback((message: string, type: AlertType, duration: number = 5000) => {
        setAlert({message, type});
        setTimeout(() => {
            setAlert({message: null, type: null});
        }, duration);
    }, []);

    return {alert, showAlert};
};
