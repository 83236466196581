import {useEffect, useState} from "react";

export const useFancyUnmount = (animationDuration: number, unmountCallback: () => void, unmountTimeout?: number) => {
    const [removed, setRemoved] = useState(false);
    const [runAnimation, setRunAnimation] = useState(false);

    const unmount = () => {
        setRunAnimation(true);

        const unmountTrack = setTimeout(() => {
            setRemoved(true);
        }, animationDuration);

        return () => {
            clearTimeout(unmountTrack);
        };
    };

    useEffect(() => {
        if (unmountTimeout) {
            const unmountTrack = setTimeout(() => {
                unmount();
            }, unmountTimeout);

            return () => {
                clearTimeout(unmountTrack);
            };
        }
    }, [unmountTimeout]);

    useEffect(() => {
        if (removed) {
            unmountCallback();
        }
    }, [removed]);

    return {
        unmount: () => {
            unmount();
        },
        runUnmountAnimation: runAnimation
    };
};
