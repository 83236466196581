import * as React from "react";
import {useState} from "react";
import {css, Theme} from "@emotion/react";
import {carsTheme} from "@rw/themes";

import {NewPasswordForm} from "./NewPasswordForm";

export const NewPasswordAccountDynamic = () => {
    const [activeForm, setActiveForm] = useState<"login" | "register" | "reset">("login");
    const handleFormChange = (FormType: "login" | "register" | "reset") => {
        setActiveForm(FormType);
    };

    return (
        <>
            <section css={pageWrapper}>
                <div css={leftCol}>
                    <div css={leftContent}>
                        <h1>Ustaw nowe hasło</h1>
                        {/*<p>*/}
                        {/*    Utwórz konto, aby dodawać ogłoszenia i zarządzać nimi w jednym miejscu. Logowanie pozwala na szybki dostęp do wszystkich funkcji*/}
                        {/*    naszej platformy, co ułatwia korzystanie z usług.*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*    Dołącz do naszej społeczności i ciesz się prostotą dodawania ogłoszeń. Dzięki rejestracji zyskujesz możliwość edytowania,*/}
                        {/*    archiwizowania oraz śledzenia swoich ogłoszeń na bieżąco.*/}
                        {/*</p>*/}
                    </div>
                    <div css={circleStyle}></div>
                </div>
                <div css={rightCol}>
                    <div css={rightColInner}>
                        <div css={formsWrapper}>
                            <NewPasswordForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const leftContent = (theme: Theme) => css`
    width: 100%;
    z-index: 100;
    color: #fff;
    position: relative;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        width: 70%;
    }
`;

const pageWrapper = (theme: Theme) => css`
    width: 100%;
    height: auto;
    flex-direction: column;
    
    @media (min-width: ${theme.breakpoints.screen_md}) {

    height: Calc(100vh - 70px);
    display: flex;
    flex-direction: row;
`;

const leftCol = (theme: Theme) => css`
    width: 100%;
    position: relative;
    min-height: 150px;
    display: flex;
    background-image: linear-gradient(to right, #ff8a49, #ff5a00);
    padding: 4rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        width: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const formsWrapper = (theme: Theme) => css`
    padding: 0;
    display: flex;
    align-items: end;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        min-height: 380px;
    }
`;

const rightColInner = (theme: Theme) => css`
    width: 100%;
    @media (min-width: ${theme.breakpoints.screen_md}) {
        width: 70%;
    }
`;
const rightCol = (theme: Theme) => css`
    padding: 4rem;

    width: 100%;
    position: relative;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        width: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

const circleStyle = css`
    width: 80%;
    height: 0;
    padding-top: 80%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ff5a00;
        border-top-right-radius: 100%;
        box-shadow: 5px -10px 12px -17px #ff5a00;
    }
`;
