import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import {appPath} from "@rw/cars_routes";
import {carsTheme} from "@rw/themes";
import {jwtDecode} from "jwt-decode";
import {Dispatch} from "redux";

import {Spinner} from "../app/components/Spinner";
import {IStore} from "../app/reducers/hybrid_reducer";
import {apiLink} from "../app/routes/api_link";
import {createRequestActionTypes} from "../app/utils/request_response_utils/factories/create_request_action_types";
import {postRequest} from "../app/utils/request_response_utils/request";
import {AddCar} from "./add_car/AddCar";
import {CarListTable} from "./car_list/CarListTable";
import {DashboardWrapper} from "./DashboardWrapper";

export const setDashboardUserOffersTypes = createRequestActionTypes({name: "setUserOffers", type: "GET", view: "Dashboard"});

const getUserOffers = (authorId: number) => async (dispatch: Dispatch) => {
    const url = apiLink.userOffers({})({author_id: authorId});

    return postRequest({}, url, {author_id: authorId})
        .then((res: any) => {
            dispatch({type: setDashboardUserOffersTypes.success, result: res});

            return res;
        })
        .catch((err) => {
            console.warn("err on reset", err);
        });
};

interface JwtPayloadData {
    data: {
        user_id: number | null;
        display_name: string | null;
    };
}

type DashViewType = "addOffer" | "showOffers";

export const DashboardDynamic = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [user, setUser] = useState<{id: number | null; displayName: string | null}>({id: null, displayName: null});
    const [view, setDashboardView] = useState<DashViewType>("addOffer");
    const dispatch = useDispatch();
    const userOffers = useSelector((store: IStore) => store.dashboard.userOfferList);

    const handleDashView = (viewType: DashViewType) => {
        setDashboardView(viewType);
    };
    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        window.location.href = appPath.admin.login;
    };
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            setIsAuthenticated(false);
            return;
        }

        // Walidacja tokena przez żądanie do API
        const validateToken = async () => {
            const url = apiLink.validateToken({})({token: token});

            return postRequest({}, url, {token: token})
                .then((res: any) => {
                    if (res.code === 200) {
                        setIsAuthenticated(true);
                        const decode: JwtPayloadData = jwtDecode(token);
                        setUser({id: decode.data.user_id, displayName: decode.data.display_name});
                        if (decode.data.user_id) {
                            dispatch(getUserOffers(decode.data.user_id));
                        }
                    } else {
                        setIsAuthenticated(false);
                    }

                    return res;
                })
                .catch((err) => {
                    console.warn("err on reset", err);
                    setIsAuthenticated(false);
                });
        };
        validateToken();
    }, []);

    if (isAuthenticated === null) {
        return (
            <DashboardWrapper>
                <div css={containerStyle}>
                    <span>Authoryzacja... </span>
                    <Spinner />
                </div>
            </DashboardWrapper>
        );
    }

    if (!isAuthenticated) {
        setTimeout(() => {
            window.location.href = appPath.admin.login;
        }, 3000);
        return (
            <DashboardWrapper>
                <div css={containerStyle}>Brak autoryzacji !</div>
            </DashboardWrapper>
        );
    }
    return (
        <DashboardWrapper>
            <p css={logoutStyle} onClick={logout}>
                {user.displayName ? user.displayName : null} Wyloguj
            </p>
            <div css={container}>
                <div css={aside}>
                    <ul css={asideNav}>
                        <li css={view === "addOffer" ? active : null} onClick={() => handleDashView("addOffer")}>
                            Dodaj ogłoszenie
                        </li>
                        <li css={view === "showOffers" ? active : null} onClick={() => handleDashView("showOffers")}>
                            Moje ogłoszenia
                        </li>
                    </ul>
                </div>
                <div css={contentWrapper}>
                    {view === "addOffer" && <AddCar user={user} />}
                    {view === "showOffers" && userOffers.length > 0 && <CarListTable cars={userOffers} />}
                </div>
            </div>
        </DashboardWrapper>
    );
};

const active = css`
    border-bottom: 2px solid ${carsTheme.colors.brand_primary};
    color: ${carsTheme.colors.brand_black};
`;
const asideNav = (theme: Theme) => css`
    li {
        list-style: none;
        margin-bottom: 6px;

        &:hover {
            cursor: pointer;
            color: ${theme.colors.brand_primary};
        }
    }
`;
const contentWrapper = css`
    width: 100%;
`;
const aside = css`
    width: 300px;
    height: 100%;
    padding: 0 4rem 0 0;
`;

const container = css`
    max-width: 1190px;
    margin: 1rem auto;
    display: flex;
`;
const logoutStyle = (theme: Theme) => css`
    &:hover {
        color: ${theme.colors.brand_primary};
    }
`;

const containerStyle = css`
    padding: 4rem;
`;
